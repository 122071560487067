@mixin hook-table-header-cell() {
  font-size:          18px;
  font-weight:        600;
  background-color:   $sn-alt-color;
  border:             1px solid $sn-border-color;

  @media (max-width: $sn-mobile-width) {
    font-size: 14px;
    padding: 8px;
  }
}

@mixin hook-table-cell() {
  font-size:          18px;
  font-weight:        375;
  border:             1px solid $sn-border-color;

  @media (max-width: $sn-mobile-width) {
    font-size: 14px;
    padding: 8px;
  }
}
