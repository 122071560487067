$button-line-height:              56px;
$button-text-color:               #000;
$button-link-color:               inherit;
$button-link-hover-color:         rgba(0,0,0,0.6);
$button-link-hover-text-decoration: underline;

$button-default-background:                      #fff;
$button-default-color:                           #000;
$button-default-hover-background:                #000;
$button-default-hover-color:                     #fff;
$button-default-active-background:               #000;
$button-default-active-color:                    #fff;

$button-primary-background:                      $sn-main-color;
$button-primary-color:                           #000;
$button-primary-hover-background:                #000;
$button-primary-hover-color:                     #fff;
$button-primary-active-background:               #000;
$button-primary-active-color:                    #fff;

a.uk-button {
  white-space: nowrap;
}

.sn-buttons {
  column-gap: 8px !important;
}

.sn-button-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  @media (max-width: $sn-mobile-width) {
    gap: 8px;
    flex-wrap: wrap;
  }
}

* + .sn-button-bar {
  margin-top: 32px !important;
  @media (max-width: $sn-mobile-width) {
    margin-top: 16px !important;
  }
}

.sn-main {
  .uk-button-default {
    background-color: #000;
    color:            #fff;
    &:hover, &:active {
      background-color: #fff;
      color:            #000;
    }
  }
  .uk-button-primary {
    background-color: #fff;
    color:            #000;
    &:hover, &:active {
      background-color: #000;
      color:            #fff;
    }
  }

  section.sn-light {
    .uk-button-default {
      background-color: #fff;
      color:            #000;
      &:hover, &:active {
        background-color: #000;
        color:            #fff;
      }
    }
    .uk-button-primary {
      background-color: $sn-main-color;
      color:            #000;
      &:hover, &:active {
        background-color: #000;
        color:            #fff;
      }
    }
  }

  section.sn-dark {
    .uk-button-default {
      background-color: #fff;
      color:            #000;
      &:hover, &:active {
        background-color: #000;
        color:            #fff;
      }
    }
  }

  .sn-content,
  .sn-deck,
  .sn-light .sn-grid {
    .uk-button-primary {
      background-color: $sn-main-color;
      color:            #000;
      &:hover, &:active {
        background-color: #000;
        color:            #fff;
      }
    }
  }
}

section.sn-alt.sn-dark {
  .uk-button-primary {
    background-color: $sn-alt-color;
    color:            #000;
    &:hover, &:active {
      background-color: #000;
      color:            #fff;
    }
  }
}

section.sn-light {
  .uk-button-primary {
    background-color: #fff ;
    color:            #000;
    &:hover, &:active {
      background-color: #000;
      color:            #fff;
    }
  }
}

.sn-dev {
  .uk-button-default {
    background-color: #000;
    color:            #fff;
    &:hover, &:active {
      background-color: #fff;
      color:            #000;
    }
  }
  .uk-button-primary {
    background-color: #fff;
    color:            #000;
    &:hover, &:active {
      background-color: #000;
      color:            #fff;
    }
  }

  section.sn-alt.sn-light,
  section.sn-dark {
    .uk-button-default {
      background-color: #fff;
      color:            #000;
      &:hover, &:active {
        background-color: #000;
        color:            #fff;
      }
    }
    .uk-button-primary {
      background-color: $sn-dev-color;
      color:            #000;
      &:hover, &:active {
        background-color: #000;
        color:            #fff;
      }
    }
  }
}

.sn-home {
  .uk-button-default {
    background-color: #000;
    color:            #fff;
    &:hover, &:active {
      background-color: #fff;
      color:            #000;
    }
  }
  .uk-button-primary {
    background-color: #fff;
    color:            #000;
    &:hover, &:active {
      background-color: #000;
      color:            #fff;
    }
  }

  section.sn-alt.sn-light,
  section.sn-dark {
    .uk-button-primary {
      background-color: $sn-home-color;
      color:            #000;
      &:hover, &:active {
        background-color: #000;
        color:            #fff;
      }
    }
  }
}
