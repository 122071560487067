@mixin hook-base-body() {
  @media (max-width: $sn-mobile-width) {
    font-size: 16px;
    line-height: 1.4;
  }
}

@mixin hook-base-h1() {
  margin-bottom: 16px;
  @media (max-width: $sn-mobile-width) {
    margin-bottom: 8px;
  }
}

@mixin hook-base-h2() {
  font-family: $sn-alt-font-family;
  font-weight: 400;
  @media (max-width: $sn-mobile-width) {
    font-size: 32px;
  }
}

@mixin hook-base-h3() {
  font-weight: 600;
  @media (max-width: $sn-mobile-width) {
    font-size: 16px;
  }
}

@mixin hook-base-blockquote() {
  font-family: $sn-alt-font-family;
}

@mixin hook-base-blockquote-footer() {
  &::before {
    content: "— ";
  }
}
