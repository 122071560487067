@media (max-width: $sn-mobile-width) {
  p {
    font-size: 16px;
    line-height: 1.4;
  }
}

.sn-subhead {
  font-family:  $sn-alt-font-family;
  font-size:    $global-large-font-size;
  line-height:  1.25;
  @media (max-width: $sn-mobile-width) {
    font-size:    18px;
    line-height:  1.3;;
  }
}

.sn-caption {
  font-size: 14px;
  opacity: 0.6;
}

cite {
  font-family: $global-font-family;
}
