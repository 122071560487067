@mixin hook-navbar-container() {
  border-bottom:  2px solid $sn-border-color;
  box-shadow:     0px 4px 4px rgba(0, 0, 0, 0.15);
  white-space:    nowrap;
}

@mixin hook-navbar() {
  min-height: 80px;
}

@mixin hook-navbar-dropbar() {
  border-bottom: 8px solid $sn-border-color;
}
