$base-heading-color:              inherit;

$base-heading-font-weight:        375;

$base-h1-font-size-m:             $global-2xlarge-font-size;
$base-h1-font-size:               40px;
$base-h1-line-height:             1.1;

$base-h2-font-size-m:             $global-xlarge-font-size;
$base-h2-font-size:               32px;
$base-h2-line-height:             1.1;

$base-h3-font-size:               $global-medium-font-size;
$base-h3-line-height:             1.4;

$base-h4-font-size:               $global-font-size;
$base-h4-line-height:             1.4;

$base-h5-font-size:               $global-font-size;
$base-h5-line-height:             1.4;

$base-h6-font-size:               $global-small-font-size;
$base-h6-line-height:             1.4;

footer {
  h2 {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 400;

    @media (max-width: $sn-mobile-width) {
      font-size: 20px;
    }
  }
}
