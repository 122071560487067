$global-color:          #000000;

$sn-alt-color-light:    #F7FBFF;
$sn-alt-color:          #BDE3FF;
$sn-alt-color-dark:     #133554;

$sn-main-color-light:   #FFFFEF;
$sn-main-color:         #FCFF64;

$sn-dev-color-light:    #F7FEE6;
$sn-dev-color:          #E1FC93;
$sn-dev-color-dark:     #354113;

$sn-home-color-light:   #FEF5FF;
$sn-home-color:         #F8CCFF;
$sn-home-color-dark:    #532E4F;

$base-em-color:         #1e73df;

$sn-border-color:       $global-color;

$sn-mobile-width:       640px;

$breakpoint-small:      $sn-mobile-width;
$breakpoint-medium:     960px;
$breakpoint-large:      1280px;
$breakpoint-xlarge:     1600px;

$base-body-font-weight: 375;

:root {
  --sn-mobile-width: $sn-mobile-width;
}

.sn-todo {
  color:                #f04d4d;
  text-decoration-line: line-through;
}

.sn-scroll-hint-down {
  transition: opacity 0.5s ease-in-out;
}

dl {
  padding-left: 2em;
}

dt {
  display: list-item;
  list-style-type: decimal;
}

dd + dt {
  margin-top: 1em;
}
