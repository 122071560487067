@mixin hook-button() {
  border: 2px solid $sn-border-color;
  border-radius: 30px;
  padding: 0 24px;
  @media (max-width: $sn-mobile-width) {
    font-size: 16px;
    line-height: 38px;
    border-width: 1px;
    padding: 0 16px;
  }
}

@mixin hook-button-link() {
  border: none;
  border-radius: unset;
  padding: 0;
  text-decoration-line: underline;
}

@mixin hook-button-text() {
  border: none;
  border-radius: unset;
  padding: 0;
  text-decoration-line: none;
}
