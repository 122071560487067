$navbar-background:                   #fff;
$navbar-nav-item-font-size:           14px;
$navbar-gap:                          32px;
$navbar-nav-item-padding-horizontal:  16px;
$navbar-nav-gap:                      24px;
$navbar-item-padding-horizontal:      0;
$navbar-nav-item-color:               $global-color;

$navbar-nav-item-height:              unset;

$navbar-dropdown-nav-item-color:          #000;
$navbar-dropdown-nav-item-hover-color:    #000;
$navbar-dropdown-nav-item-active-color:   unset;

@media (max-width: 1150px) {
  .uk-navbar .uk-navbar-item.sn-login {
    display: none !important;
  }
}

@media (max-width: 1400px) {
  .uk-navbar .uk-navbar-item.sn-buttons {
    display: none !important;
  }
}
