$table-cell-padding-vertical:     24px;
$table-cell-padding-horizontal:   24px;

$table-striped-row-background:    #fff;

.uk-table-striped > tr:nth-of-type(even),
.uk-table-striped tbody tr:nth-of-type(even) {
    background: $sn-alt-color-light;
}

.sn-alt {
  .uk-table thead th {
    background-color:   $sn-alt-color-dark;
    color:              #fff;
  }
}
