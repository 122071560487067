$global-font-family:  "Suisse Screen", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$sn-alt-font-family: "Suisse Works", serif;

$global-2xlarge-font-size:      60px;
$global-xlarge-font-size:       50px;
$global-large-font-size:        28px;
$global-medium-font-size:       24px;
$global-font-size:              24px;
$global-small-font-size:        16px;

$global-line-height:            1.4;

$global-medium-margin:          32px;

$base-blockquote-font-size:     18px;

@font-face {
	font-family: 'Suisse Screen';
	font-weight: 100 599;
	src: url('../fonts/SuisseScreen-Monitor-WebXL.woff2') format('woff2');
}

@font-face {
	font-family: 'Suisse Screen';
	font-weight: 600 1000;
	src: url('../fonts/SuisseScreen-SemiBold-WebXL.woff2') format('woff2');
}

@font-face {
	font-family: 'Suisse Works';
	src: url('../fonts/SuisseWorks-Regular-WebXL.woff2') format('woff2');
}

@font-face {
	font-family: 'Source Code Pro';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/source-code-pro-v22-latin-300.woff2') format('woff2');
}

@font-face {
	font-family: 'Source Code Pro';
	font-style: italic;
	font-weight: 300;
	src: url('../fonts/source-code-pro-v22-latin-300italic.woff2') format('woff2');
}

@font-face {
	font-family: 'Source Code Pro';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/source-code-pro-v22-latin-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Source Code Pro';
	font-style: italic;
	font-weight: 400;
	src: url('../fonts/source-code-pro-v22-latin-italic.woff2') format('woff2');
}

@font-face {
	font-family: 'Source Code Pro';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/source-code-pro-v22-latin-700.woff2') format('woff2');
}

@font-face {
	font-family: 'Source Code Pro';
	font-style: italic;
	font-weight: 700;
	src: url('../fonts/source-code-pro-v22-latin-700italic.woff2') format('woff2');
}
