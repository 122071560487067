$subnav-margin-horizontal:                       16px;

$subnav-pill-item-padding-vertical:              0;
$subnav-pill-item-padding-horizontal:            0;

$subnav-pill-item-color:                         $button-text-color;

$subnav-pill-item-active-background:             $sn-main-color;
$subnav-pill-item-active-color:                  $button-text-color;

$subnav-pill-item-hover-background:              #000;
$subnav-pill-item-hover-color:                   #fff;

.uk-subnav-pill > li > a {
  text-decoration: none !important;
}
