$nav-item-padding-horizontal:                      16px;

nav {
  .uk-button {
    border-width:   0;
    font-size:      $navbar-nav-item-font-size;
    line-height:    35px;
  }
  .uk-button.uk-button-default {
    border-width:       1px;
    line-height:        33px; // 35px desired height, so allow for 2x of top/bottom borders
    background-color: #fff;
    color:              $navbar-nav-item-color;
    &:hover, &:active {
      background-color: #000;
      color:            #fff;
    }
  }
  .uk-button.uk-button-primary {
    background-color:   $sn-main-color;
    border-width:       1px;
    line-height:        33px; // 35px desired height, so allow for 2x of top/bottom borders
    &:hover, &:active {
      background-color: #000;
      color:            #fff;
    }
  }

  .sn-selected .uk-button {
    background-color: $sn-main-color;
  }

  .uk-nav > li {
    margin: 12px 0;

    & > a {
      display: inline-flex;
    }
  }
}

.sn-dev {
  nav {
    .sn-selected .uk-button {
      background-color: $sn-dev-color;
    }
  }
}

.sn-home {
  nav {
    .sn-selected .uk-button {
      background-color: $sn-home-color;
    }
  }
}
