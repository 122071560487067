$section-padding-vertical:    0;
$section-padding-vertical-m:  0;

section + section {
  border-top: 2px solid $sn-border-color;
}

section.sn-alt.sn-light + section.sn-alt.sn-light {
  border-top: none;
}

section.sn-deck,
section.sn-logos {
  background-color:       #fff !important;
}

section.sn-hero {
  .sn-info-card {
    padding-right: 80px;
    @media (max-width: $sn-mobile-width) {
      padding-right: 24px;
    }
  }

  .sn-img-card {
    padding: 48px;
    background-size: contain;
    @media (max-width: $sn-mobile-width) {
      padding: 24px 24px 0 24px;
    }
  }
}

section.sn-alt {
  background-color:       $sn-alt-color !important;
}

section.sn-alt.sn-dark {
  background-color:       $sn-alt-color-dark !important;
  color:                  #fff !important;
}

section.sn-alt.sn-light {
  background-color:       #fff !important;
}

section.sn-light {
  background-color:       #fff !important;

  a:not(.uk-button):not(.uk-button-text) {
    text-decoration-line: underline;
  }
}

section.sn-dark.sn-neutral {
  background-color:      #3a3a3a !important;
}

section.sn-story {
  background-color:       #fff !important;
}

.sn-main {
  section {
    background-color:     $sn-main-color;
  }
}

.sn-dark {
  color:                  #fff;

  a:not(.uk-button) {
    color:                #fff;
    text-decoration-line: underline;
  }
}

.sn-dev {
  section {
    background-color:     $sn-dev-color;
  }
  section.sn-dark {
    background-color:     $sn-dev-color-dark !important;
    color:                #fff !important;
  }
  section.sn-light:not(.sn-alt) {
    background-color:     $sn-dev-color !important;
    color:                #000 !important;
  }
}

.sn-home {
  section {
    background-color:     $sn-home-color;
  }
  section.sn-dark {
    background-color:     $sn-home-color-dark !important;
    color:                #fff !important;
  }
  section.sn-light:not(.sn-alt) {
    background-color:     $sn-home-color !important;
    color:                #000 !important;
  }
}

section.sn-how {
  background-color:       #fff !important;
}

section > .sn-content {
  padding: 100px 244px;
  column-gap: 80px;

  @media (max-width: $breakpoint-large) {
    padding: 80px 122px;
    column-gap: 60px;
  }
  @media (max-width: $breakpoint-medium) {
    padding: 60px 64px;
    column-gap: 40px;
  }
  @media (max-width: $sn-mobile-width) {
    padding: 40px 24px;
  }

  h1 {
    font-size: 36px;
    font-weight: 375;
    @media (max-width: $sn-mobile-width) {
      font-size: 24px;
    }
  }
  h2 {
    font-size: 28px;
    font-weight: 375;
    font-family: $global-font-family;
    @media (max-width: $sn-mobile-width) {
      font-size: 20px;
    }
  }
  .sn-button-bar {
    justify-content: center;
  }
}

section > .sn-col {
  padding: 100px 244px;
  gap: 32px;
  align-items: center;
  text-align: center !important;

  @media (max-width: $breakpoint-large) {
    padding: 80px 122px;
    column-gap: 60px;
  }
  @media (max-width: $breakpoint-medium) {
    padding: 60px 64px;
    column-gap: 40px;
  }
  @media (max-width: $sn-mobile-width) {
    padding: 40px 24px;
    gap: 16px;
  }

  p {
    font-size: 24px;
    line-height: 1.5;

    @media (max-width: $sn-mobile-width) {
      font-size: 16px;
      line-height: 1.4;
    }
  }
}

section.sn-topic {
  h1 {
    font-size: 24px;
    line-height: 1.4;
    text-transform: uppercase;
    @media (max-width: $sn-mobile-width) {
      font-size: 16px;
    }
  }

  p {
    font-size: 24px;
    line-height: 1.5;

    @media (max-width: $sn-mobile-width) {
      font-size: 16px;
      line-height: 1.4;
    }
  }
  .sn-info-card {
    padding-top: 160px;
    padding-bottom: 160px;
    @media (max-width: $breakpoint-large) {
      padding-top: 40px;
      padding-bottom: 40px;
      }
  }
}

section + footer {
  font-size: 18px;
  line-height: 1.4;
  border-top: 2px solid $sn-border-color;

  @media (max-width: $sn-mobile-width) {
    font-size: 16px;
  }

  & > .sn-row {
    padding: 80px 40px;
    justify-content: space-between;
    align-items: stretch;
    row-gap: 40px;

    @media(max-width: $sn-mobile-width) {
      padding: 40px 16px;
      flex-wrap: wrap;
    }

    .sn-col {
      justify-content: space-between;
    }
  }

  a {
    font-size: 18px !important;
    text-align: left !important;

    @media (max-width: $sn-mobile-width) {
      font-size: 16px !important;
    }
  }
}
