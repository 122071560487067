.sn-container {
  max-width: 1440px;
  box-sizing: border-box;
  margin: 0 auto;
}

.sn-wrap {
  flex-wrap: wrap !important;
}

.sn-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc($sn-mobile-width / 2 + 40px), 1fr));

  & > a {
    text-decoration-line: none !important;
    &:hover {
      text-decoration-line: none !important;
    }
  }

  & > .sn-col > .sn-button-bar {
      justify-content: start;
  }
}

// top-level section row on mobile: show image item first
@media (max-width: $sn-mobile-width) {
  .sn-container.sn-grid:not(.sn-logos) > *.sn-img-card:last-child {
    order: -1;
  }
}

.sn-lead {
  font-size:  $global-large-font-size;
  line-height: 1.5;
  @media (max-width: $sn-mobile-width) {
    font-size:    $global-small-font-size;
    line-height:  $global-line-height;
    }
}

.sn-dark .sn-card-info p {
  font-size: 20px;
  line-height: 1.4;
  @media (max-width: $sn-mobile-width) {
    font-size: 16px;
  }
}

.sn-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  @media (max-width: $sn-mobile-width) {
    gap: 16px;
    flex-wrap: wrap;
  }
}

.sn-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: $sn-mobile-width) {
    gap: 8px;
  }
}

.sn-info-card {
  padding: 80px 122px;

  @media (max-width: $breakpoint-medium) {
    padding: 60px 64px;
  }
  @media (max-width: $sn-mobile-width) {
    padding: 40px 24px;
  }

  > .sn-button-bar {
    justify-content: center;
  }

  .sn-info-card {
    padding: 40px 40px;

    @media (max-width: $sn-mobile-width) {
      padding: 20px 0;
    }
  }
}

.sn-img-card {
  overflow: clip;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  min-height: 320px;
}

.sn-grid:not(.sn-compact) .sn-img-card.sn-icon {
  min-height: 384px;
}

.sn-logos {
  padding: 100px 80px;
  gap: 48px;
  @media (max-width: $sn-mobile-width) {
    gap: 48px;
    padding: 48px 24px;
  }
}

.sn-logos.uk-slider > .uk-slider-items {
  align-items: center;
  & > * {
    text-align: center;
  }
}

.sn-logos .sn-img-card {
  background-size: contain;
  min-height: 60px;
  max-height: unset;
  @media (max-width: $sn-mobile-width) {
    min-height: 40px;
  }
}

.sn-deck {
  h1 {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 40px;
    @media (max-width: $sn-mobile-width) {
      margin-bottom: 24px;
    }
  }

  .sn-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: unset;
    gap: 48px 24px;

    @media (max-width: $breakpoint-medium) {
      grid-template-columns: 1fr !important;
    }
    @media (max-width: $sn-mobile-width) {
      grid-template-columns: 1fr !important;
    }

    & > * {
      border: 1px solid $sn-border-color;
      background-color: $sn-main-color;
    }

    & > .sn-col {
      align-items: center;
      gap: 0;

      h2 {
        font-size: 24px;
        font-family: $global-font-family;
        line-height: 1.4;
        font-weight: 600;

        @media (max-width: $sn-mobile-width) {
          font-size: 18px;
        }
      }

      & > img {
        margin: 0;
        padding: 48px;
        max-height: 300px;
        @media (max-width: $sn-mobile-width) {
          padding: 24px;
          max-height: 200px;
        }
      }

      & > img + img {
        padding-top: 0;
      }

      & > div:last-child {
        padding: 24px;
        height: 100%;
        @media (max-width: $sn-mobile-width) {
          padding: 24px;
        }
      }

      & > * + div {
        border-top: 1px solid $sn-border-color;
        background-color: #fff;
      }
    }
  }
}

.sn-deck:not(.sn-flash) .sn-grid > .sn-col > div:not(.sn-img-card) + div {
  padding: 0 !important;
}

.sn-deck.sn-icon .sn-grid > .sn-col > img {
  max-height: 100px !important;
}

.sn-grid.sn-compact {
  margin: 80px 0;

  @media (max-width: $breakpoint-large) {
    grid-template-columns: repeat(2, 1fr) !important;
    margin: 40px 0;
  }

  & > .sn-col {
    gap: 24px;
    & > div:last-child > * {
      margin: 0;
    }
  }

  .sn-img-card {
    min-height: 180px;
  }
}

.sn-deck.sn-cards {
  h1 {
    font-size: 36px;

    @media (max-width: $sn-mobile-width) {
      font-size: 24px;
    }
  }
  .sn-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-auto-flow: unset;
    gap: 24px;

    @media (max-width: $sn-mobile-width) {
      grid-template-columns: 1fr !important;
    }

    .sn-info-card {
      background-color: #fff;
      color:            #000;

      @media (max-width: $sn-mobile-width) {
        padding: 24px;
      }
    }

    .sn-col {
      align-items: flex-start;
      justify-content: start;
      gap: 16px;
    }
  }
}

.sn-dark .sn-deck,
.sn-light:not(.sn-alt) .sn-deck {
  text-align: center !important;

  .sn-grid {
    & > * {
      border: none !important;
      background-color: transparent !important;
    }
    & > .sn-col {
      & > img {
        padding: 0;
        max-width: 240px;
        @media (max-width: $sn-mobile-width) {
          max-width: 180px;
        }
      }
      & > div {
        border-top: none !important;
        background-color: transparent !important;
      }
    }
  }
}

.sn-deck.sn-learn {
  h1 {
    text-align: start;
    margin-left: 0;
    margin-right: 0;
  }
  .sn-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: unset;
    gap: 24px;

    & > .sn-col {
      h2 {
        font-family: $global-font-family;
        font-weight: 375;
        line-height: 1.5;
      }
      & > div.sn-img-card {
        width: 100%;
      }
      & > div.sn-img-card.sn-icon {
        background-size: contain;
        box-sizing: border-box;
        padding: 32px;
        min-height: 180px;
      }
      & > img.sn-icon {
        display: inline-block;
        padding: 32px;
        height: 240px;
        @media (max-width: $sn-mobile-width) {
          height: 180px;
        }
      }
      & > div:not(.sn-img-card) {
        padding: 32px;

        &:last-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
          width: 100%;
        }
      }
    }
    .sn-main {
      background-color: $sn-main-color-light;
      a {
        color: $sn-alt-color-dark;
      }
      h2 {
        color: $sn-alt-color-dark;
      }
    }

    .sn-dev {
      background-color: $sn-dev-color-light;
      a {
        color: $sn-dev-color-dark;
      }
      h2 {
        color: $sn-dev-color-dark;
      }
    }

    .sn-home {
      background-color: $sn-home-color-light;
      a {
        color: $sn-home-color-dark;
      }
      h2 {
        color: $sn-home-color-dark;
      }
    }
  }
}

.sn-deck.sn-learn:not(.sn-pair) .sn-grid {
  & > img,
  & > div.sn-img-card {
    margin: 0;
    min-height: 240px;
    max-height: 240px;
    @media (max-width: $sn-mobile-width) {
      min-height: 180px;
      max-height: 180px;
    }
  }
}

.sn-deck.sn-pair {
  .sn-grid {
    grid-template-columns: repeat(2, 1fr);

    & > .sn-col {
      h2 {
        font-family: $global-font-family;
        font-weight: 400;
        line-height: 1.1;
        text-transform: uppercase;
      }
      h3 {
        font-size: 24px;
        line-height: 1.4;
        font-weight: 600;
      }
      p {
        font-size: 24px;
        line-height: 1.4;
        @media (max-width: $sn-mobile-width) {
          font-size: 16px;
        }
      }
    }
  }
}

.sn-deck.sn-pair.sn-extra .sn-grid > .sn-col p {
  font-size: 18px;
  @media (max-width: $sn-mobile-width) {
    font-size: 14px;
  }
}

.sn-deck.sn-pair:not(.sn-compact) .sn-grid > .sn-col {
  & > img,
  & > div.sn-img-card {
    margin: 0;
    padding: 32px;
    min-height: 520px;
    max-height: 520px;
    @media (max-width: $sn-mobile-width) {
      padding: 24px;
      min-height: 300px;
      max-height: 300px;
    }
  }
}

.sn-deck.sn-pair.sn-extra .sn-grid > .sn-col {
  & > img,
  & > div.sn-img-card {
    min-height: 120px;
    max-height: 120px;
    @media (max-width: $sn-mobile-width) {
      min-height: 120px;
      max-height: 120px;
    }
  }
}

.sn-deck.sn-pair.sn-compact {
  .sn-grid {
    grid-template-columns: repeat(3, 1fr);

    & > .sn-col {
      & > img,
      & > div.sn-img-card {
        max-height: 320px;
        @media (max-width: $sn-mobile-width) {
          max-height: 240px;
        }
      }
    }
  }
}

.sn-deck.sn-compact .sn-grid > .sn-col > .sn-img-card:not(.sn-icon) {
  min-height: 180px;
  @media (max-width: $sn-mobile-width) {
    min-height: 120px;
  }
}

.sn-main {
  .sn-deck.sn-cards {
    .sn-grid.sn-dark > * {
      background-color: #fff;
      color: #000;
      @media (min-width: $sn-mobile-width) {
        &:nth-child(4n + 1),
        &:nth-child(4n + 4) {
          background-color: $sn-alt-color-dark;
          color:          #fff;
        }
      }
    }
  }
}

.sn-dev {
  .sn-deck {
    .sn-grid > * {
      background-color: $sn-dev-color;
    }
  }
  .sn-light:not(.sn-alt) .sn-deck {
    background-color: $sn-dev-color;
  }
  .sn-deck.sn-cards {
    .sn-grid.sn-dark > *{
      background-color: #fff;
      color: #000;
      @media (min-width: $sn-mobile-width) {
        &:nth-child(4n + 1),
        &:nth-child(4n + 4) {
          background-color: $sn-dev-color-dark;
          color:          #fff;
        }
      }
    }
  }
}

.sn-home {
  .sn-deck {
    .sn-grid > * {
      background-color: $sn-home-color;
    }
  }
  .sn-light:not(.sn-alt) .sn-deck {
    background-color: $sn-home-color;
  }
  .sn-deck.sn-cards {
    .sn-grid.sn-dark > * {
      background-color: #fff;
      color: #000;
      @media (min-width: $sn-mobile-width) {
        &:nth-child(4n + 1),
        &:nth-child(4n + 4) {
          background-color: $sn-home-color-dark;
          color:          #fff;
        }
      }
    }
  }
}

.sn-light:not(.sn-alt) .sn-deck {
  h1 {
    font-size: 36px;
    @media (max-width: $sn-mobile-width) {
      font-size: 24px;
    }
  }
  .sn-grid > .sn-col h2 {
    font-size: 32px;
    font-weight: 375;
    @media (max-width: $sn-mobile-width) {
      font-size: 24px;
    }
  }
  p {
    font-size: 18px;
    @media (max-width: $sn-mobile-width) {
      font-size: 16px;
    }
  }
}

.sn-deck.sn-flash {
  h2 {
    font-size: 36px;
    line-height: 1.4;
    font-weight: 375;
  }
  p {
    font-size: 24px;
    line-height: 1.4;
    @media (max-width: $sn-mobile-width) {
      font-size: 16px;
    }
  }
  @media (max-width: $sn-mobile-width) {
    h1 {
      font-size: 30px;
      line-height: 1.1;
    }
    h2 {
      font-size: 24px;
      line-height: 1.3;
    }
  }
  .sn-grid {
    grid-auto-flow: unset;
    gap: 24px;

    & > .sn-col {
      align-items: flex-start;
      background-color: $sn-alt-color-dark;
      gap: 0;
      & > div {
        padding: 24px !important;
        box-sizing: border-box;
        width: 100%;
        &:first-child {
          color: white;
        }
        &:last-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 1em;
        }
      }
      & > div > * {
        margin: 0;
      }
    }
  }
}

.sn-grid.sn-single {
  grid-template-columns: 1fr;
}

.sn-grid.sn-double {
  grid-template-columns: repeat(2, 1fr);
}

.sn-grid.sn-triple {
  grid-template-columns: repeat(3, 1fr);
}

.sn-grid.sn-quad {
  grid-template-columns: repeat(4, 1fr);
}

.sn-how .uk-switcher > .sn-row {
  flex-wrap: nowrap;
  @media (max-width: $sn-mobile-width) {
    flex-wrap: wrap;
  }
  & > *:first-child {
    flex-basis: 300px;
    @media (max-width: $sn-mobile-width) {
      flex-basis: $sn-mobile-width;
      order: 1;
    }
  }
}

.sn-notes {
  font-size: 18px;
}

.sn-story {
  font-size: 18px;
  h1 {
    font-size: 28px;
    line-height: 1.25;
    font-family: $sn-alt-font-family;
    font-weight: 450;
    margin-top: 0;
    @media (max-width: $sn-mobile-width) {
      font-size: 18px;
      line-height: 1.4;
    }
  }
  h2 {
    font-size: 18px;
  }
}

.sn-scroll-hint {
  max-height: 420px;
  overflow-y: scroll;
  @media (max-width: $sn-mobile-width) {
    max-height: unset;
    overflow-y: unset;
    .sn-scroll-hint-down {
      display: none;
    }
  }
}


.sn-cues {
  padding: 80px 122px;

  @media (max-width: $sn-mobile-width) {
    padding: 40px 24px;
  }

  h3 {
    font-size: 36px;
    font-weight: 375;
    margin: 0;
  }

  h4 {
    font-size: 32px;
    margin: 0;
  }

  & > div + div {
    margin-top: 40px;
    @media (max-width: $sn-mobile-width) {
      margin-top: 24px;
    }
  }

  .sn-col {
    gap: 0;
    & > div {
      border: 1px solid $sn-border-color;
      padding: 24px;
      &:first-child {
        background-color: $sn-alt-color-dark;
        color:          white;
      }
      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 48px 64px;
        @media (max-width: $sn-mobile-width) {
          padding: 24px;
        }
      }
    }
  }

  .sn-grid {
    gap: 40px;
    @media (max-width: $sn-mobile-width) {
      gap: 24px;
    }
  }

  .sn-row {
    align-items: center;
    flex-wrap: wrap;

    & > span {
      @media (max-width: $sn-mobile-width) {
        width: 100%;
      }
    }
  }

}

.sn-row.sn-content {
  font-size: 18px;

  @media (max-width: $sn-mobile-width) {
    font-size: 14px;
  }
  .uk-text-large {
    font-size: 24px;
    font-weight: 375;
    @media (max-width: $sn-mobile-width) {
      font-size: 14px;
    }
  }
}

.sn-row.sn-content + .sn-row.sn-content {
  padding-top: 0 !important;
}

.sn-text-logo {
  display: inline-block;
  padding: 32px;
  font-size: $global-2xlarge-font-size;
  border: 1px solid hsl(var(--sn-color-h), var(--sn-color-s), var(--sn-color-l));
  color: hsl(var(--sn-color-h), var(--sn-color-s), var(--sn-color-l));
  line-height: 1.5em;
  min-width: 1.5em;
  text-align: center;

  box-shadow: 0 0 0.1rem #fff,
              0 0 0.1rem #fff,
              0 0 1rem hsl(var(--sn-color-h), var(--sn-color-s), var(--sn-color-l)),
              0 0 0.4rem hsl(var(--sn-color-h), var(--sn-color-s), var(--sn-color-l)),
              0 0 1.4rem hsl(var(--sn-color-h), var(--sn-color-s), var(--sn-color-l)),
              inset 0 0 0.7rem hsl(var(--sn-color-h), var(--sn-color-s), var(--sn-color-l));

  @media (max-width: $sn-mobile-width) {
    padding: 24px;
    font-size: $global-large-font-size;
  }
}

.sn-text-logo-small {
  padding: 24px;
  font-size: $global-large-font-size;
  @media (max-width: $sn-mobile-width) {
    padding: 18px;
    font-size: $global-medium-font-size;
  }
}

.sn-partners {
  img {
    max-width: 300px;
    max-height: 80px;
    @media (max-width: $sn-mobile-width) {
      max-width: 240px;
      max-height: 60px;
    }
  }
}

.sn-row.sn-shoutout {
  align-items: center;

  & > img {
    max-width: 120px;
  }
}
